import { createAsyncThunk } from "@reduxjs/toolkit";
import { bus, getClosestBus } from "services/functions";
import { IBusesData, IGetBusData } from "./types";
import { setBuses, setClosestBus } from "./slice";

export const getBus = createAsyncThunk(
  "buses/getBus",
  async (data: IGetBusData, { rejectWithValue, dispatch }) => {
    try {
      const [busData, closestBusData] = await Promise.allSettled([
        bus(data),
        getClosestBus(data),
      ]);

      const resBusData =
        busData.status === "fulfilled" ? busData.value.data : null;

      dispatch(setBuses(resBusData));

      const resClosestBusData =
        closestBusData.status === "fulfilled"
          ? closestBusData.value.data
          : null;

      if (!resClosestBusData) {
        dispatch(setClosestBus(resClosestBusData));
      }

      const normalize = (resClosestBusData as IBusesData[]).reduce(
        (acc: IBusesData[], el, item) => {
          if (!acc.some((bus) => bus.departureDate === el.departureDate)) {
            acc.push(el);
          }
          return acc;
        },
        []
      );
      dispatch(setClosestBus(normalize));

      return;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
 