import dayjs from "dayjs";
import { useTickets } from "features/tickets/useTickets";
import { useSearchForm } from "features/searchform/useShopForm";
import {
  NotFoundBtnContainer,
  NotFoundBtnList,
  NotFoundBusContiner,
  NotFoundSubTitle,
  NotFoundSubTitleBox,
  NotFoundTitle,
  NotFoundTitleBox,
  StyledFmdBadIcon,
  StyledSearchIconIcon,
} from "./style";
import { Box, Button } from "@mui/material";
import { useBuses } from "features/buses/useBuses";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

export default function NotFoundBus() {
  const { ticketsTo } = useTickets();
  const { formData, setFormData } = useSearchForm();
  const { closestBus } = useBuses();

  const { i18n, t } = useTranslation();

  return (
    <NotFoundBusContiner elevation={2}>
      <NotFoundTitleBox>
        <NotFoundTitle>
          {t("tickets_for")}{" "}
          {dayjs(formData.date_sent).locale(i18n.language).format("DD MMMM")}{" "}
          {t("not_founds")}
        </NotFoundTitle>
        <StyledFmdBadIcon />
      </NotFoundTitleBox>
      {closestBus && closestBus.length > 0 && (
        <NotFoundBtnContainer>
          <NotFoundSubTitleBox>
            <StyledSearchIconIcon />
            <NotFoundSubTitle>{t("we-found-tickets")}:</NotFoundSubTitle>
          </NotFoundSubTitleBox>

          <NotFoundBtnList>
            {closestBus
              .filter((e) => e.freeSeats)
              .map((e) => (
                <Box key={nanoid()}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      !!ticketsTo
                        ? setFormData({
                            date_return: dayjs(e.departureDate).format(
                              "YYYY-MM-DD"
                            ),
                          })
                        : setFormData({
                            date_sent: dayjs(e.departureDate).format(
                              "YYYY-MM-DD"
                            ),
                            date_return: dayjs(e.departureDate)
                              .add(1, "day")
                              .format("YYYY-MM-DD"),
                          });
                    }}
                  >
                    {dayjs(e.departureDate)
                      .locale(i18n.language)
                      .format("DD MMM")}
                  </Button>
                </Box>
              ))}
          </NotFoundBtnList>
        </NotFoundBtnContainer>
      )}
    </NotFoundBusContiner>
  );
}
