// import { createBrowserRouter } from "react-router-dom";
// import Main from "pages/Main";
// import Contacts from "pages/Contacts";
// import News from "pages/News";
// // import Layout from "components/MainLayout";
// import Buses from "pages/Buses";
// import Error from "pages/Error";
// import FaqPage from "pages/Faq";
// import Checkout from "pages/Checkout";
// import Successful from "pages/SuccessfulBuy";
// import { createRouteId } from "./utils/createRouteId";
// import PrivacyPolicyPage from "pages/PrivacyPolicy";
// import Rules from "pages/Rules";
// import Profile from "pages/Profile";
// import HistoryPage from "pages/Profile/History";
// import BusesManagement from "pages/Profile/BusesManagement";
// import ProfileSettings from "pages/Profile/ProfileSettings";
// import ReviewsPage from "pages/Profile/Reviews";
// import AboutUsPage from "pages/AboutUs";
// import ManagementNews from "pages/Profile/ManagementNews";
// import NewsDetail from "pages/NewsDetail";
// import LanguagePath from "./LanguagePath";
// import ProtectedRoute from "./ProtectedRoute";
// import Oferta from "pages/Oferta";
// import ReportPage from "pages/Profile/ReportPage";
// import FirstLayout from "components/FirstLayout";
// import SecondLayout from "components/SecondLayout";
// import { lazy, Suspense } from "react";
// import LoaderPage from "components/LoaderPage";
// import MainLayout from "components/MainLayout";
// const Layout = lazy(() => import("components/MainLayout"));

// const components = [
//   {
//     index: true,
//     element: (
//       <FirstLayout>
//         <Main />
//       </FirstLayout>
//     ),
//   },

//   {
//     path: "faq",
//     element: (
//       <FirstLayout>
//         <FaqPage />
//       </FirstLayout>
//     ),
//   },
//   {
//     path: "rules",
//     element: (
//       <FirstLayout>
//         <Rules />
//       </FirstLayout>
//     ),
//   },

//   {
//     path: "news",
//     element: (
//       <FirstLayout>
//         <News />
//       </FirstLayout>
//     ),
//   },
//   {
//     path: "news/:id",
//     element: (
//       <FirstLayout>
//         <NewsDetail />
//       </FirstLayout>
//     ),
//   },
//   {
//     path: "contacts",
//     element: (
//       <FirstLayout>
//         <Contacts />
//       </FirstLayout>
//     ),
//   },

//   {
//     path: "buses",
//     element: (
//       <SecondLayout>
//         <Buses />
//       </SecondLayout>
//     ),
//   },
//   {
//     path: "checkout",
//     element: (
//       <SecondLayout>
//         <Checkout />
//       </SecondLayout>
//     ),
//   },
//   {
//     path: "aboutus",
//     element: (
//       <FirstLayout>
//         <AboutUsPage />
//       </FirstLayout>
//     ),
//   },
//   {
//     path: "successful",
//     element: (
//       <FirstLayout>
//         <Successful />
//       </FirstLayout>
//     ),
//   },
//   {
//     path: "oferta",
//     element: (
//       <FirstLayout>
//         <Oferta />
//       </FirstLayout>
//     ),
//   },
//   {
//     path: "privacypolicy",
//     element: (
//       <FirstLayout>
//         <PrivacyPolicyPage />
//       </FirstLayout>
//     ),
//   },
//   {
//     element: (
//       <SecondLayout>
//         <ProtectedRoute />
//       </SecondLayout>
//     ),
//     children: [
//       {
//         path: paths.agent.path,
//         element: <Profile />,
//         id: createRouteId("agent"),
//         children: [
//           { index: true, element: <BusesManagement /> },
//           {
//             path: "history",
//             element: <HistoryPage />,
//           },
//           { path: "settings", element: <ProfileSettings /> },
//         ],
//       },
//       {
//         path: paths.admin.path,
//         element: <Profile />,
//         id: createRouteId("admin"),
//         children: [
//           { index: true, element: <BusesManagement /> },
//           {
//             path: "history",
//             element: <HistoryPage />,
//           },
//           { path: "settings", element: <ProfileSettings /> },
//           { path: "reviews", element: <ReviewsPage /> },
//           { path: "manager-news", element: <ManagementNews /> },
//           { path: "report", element: <ReportPage /> },
//         ],
//       },
//       {
//         path: paths.user.path,
//         element: <Profile />,
//         id: createRouteId("user"),
//         children: [
//           { index: true, element: <ProfileSettings /> },
//           {
//             path: "history",
//             element: <HistoryPage />,
//           },
//           { path: "reviews", element: <ReviewsPage /> },
//         ],
//       },
//     ],
//   },
// ];

// const router = createBrowserRouter([
//   {
//     element: (
//       <Suspense >
//         <Layout />
//       </Suspense>
//     ),
//     errorElement: <Error />,

//     path: "/",
//     children: [
//       {
//         path: "/:lang",
//         element: <LanguagePath />,
//         children: components,
//       },
//     ],
//   },
// ]);

// export default router;

import { createBrowserRouter } from "react-router-dom";
import { lazy, Suspense } from "react";
import LoaderPage from "components/LoaderPage";
import Error from "pages/Error";
import LanguagePath from "./LanguagePath";
import ProtectedRoute from "./ProtectedRoute";
import FirstLayout from "components/FirstLayout";
import SecondLayout from "components/SecondLayout";
import { paths } from "./utils/paths";
import { createRouteId } from "./utils/createRouteId";

// Ленивое подключение страниц
const Main = lazy(() => import("pages/Main"));
const Contacts = lazy(() => import("pages/Contacts"));
const News = lazy(() => import("pages/News"));
const Buses = lazy(() => import("pages/Buses"));
const FaqPage = lazy(() => import("pages/Faq"));
const Checkout = lazy(() => import("pages/Checkout"));
const Successful = lazy(() => import("pages/SuccessfulBuy"));
const PrivacyPolicyPage = lazy(() => import("pages/PrivacyPolicy"));
const Rules = lazy(() => import("pages/Rules"));
const Profile = lazy(() => import("pages/Profile"));
const HistoryPage = lazy(() => import("pages/Profile/History"));
const BusesManagement = lazy(() => import("pages/Profile/BusesManagement"));
const ProfileSettings = lazy(() => import("pages/Profile/ProfileSettings"));
const ReviewsPage = lazy(() => import("pages/Profile/Reviews"));
const AboutUsPage = lazy(() => import("pages/AboutUs"));
const ManagementNews = lazy(() => import("pages/Profile/ManagementNews"));
const NewsDetail = lazy(() => import("pages/NewsDetail"));
const Oferta = lazy(() => import("pages/Oferta"));
const ReportPage = lazy(() => import("pages/Profile/ReportPage"));
const Layout = lazy(() => import("components/MainLayout"));

// Определение маршрутов
const components = [
  {
    index: true,
    element: (
      <FirstLayout>
        <Suspense>
          <Main />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    path: "faq",
    element: (
      <FirstLayout>
        <Suspense>
          <FaqPage />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    path: "rules",
    element: (
      <FirstLayout>
        <Suspense>
          <Rules />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    path: "news",
    element: (
      <FirstLayout>
        <Suspense>
          <News />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    path: "news/:id",
    element: (
      <FirstLayout>
        <Suspense>
          <NewsDetail />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    path: "contacts",
    element: (
      <FirstLayout>
        <Suspense>
          <Contacts />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    path: "buses",
    element: (
      <SecondLayout>
        <Suspense>
          <Buses />
        </Suspense>
      </SecondLayout>
    ),
  },
  {
    path: "checkout",
    element: (
      <SecondLayout>
        <Suspense>
          <Checkout />
        </Suspense>
      </SecondLayout>
    ),
  },
  {
    path: "aboutus",
    element: (
      <FirstLayout>
        <Suspense>
          <AboutUsPage />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    path: "successful",
    element: (
      <FirstLayout>
        <Suspense>
          <Successful />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    path: "oferta",
    element: (
      <FirstLayout>
        <Suspense>
          <Oferta />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    path: "privacypolicy",
    element: (
      <FirstLayout>
        <Suspense>
          <PrivacyPolicyPage />
        </Suspense>
      </FirstLayout>
    ),
  },
  {
    element: (
      <SecondLayout>
        <ProtectedRoute />
      </SecondLayout>
    ),
    children: [
      {
        path: paths.agent.path,
        element: (
          <Suspense>
            <Profile />
          </Suspense>
        ),
        id: createRouteId("agent"),
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <BusesManagement />
              </Suspense>
            ),
          },
          {
            path: "history",
            element: (
              <Suspense>
                <HistoryPage />
              </Suspense>
            ),
          },
          {
            path: "settings",
            element: (
              <Suspense>
                <ProfileSettings />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: paths.admin.path,
        element: (
          <Suspense>
            <Profile />
          </Suspense>
        ),
        id: createRouteId("admin"),
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <BusesManagement />
              </Suspense>
            ),
          },
          {
            path: "history",
            element: (
              <Suspense>
                <HistoryPage />
              </Suspense>
            ),
          },
          {
            path: "settings",
            element: (
              <Suspense>
                <ProfileSettings />
              </Suspense>
            ),
          },
          {
            path: "reviews",
            element: (
              <Suspense>
                <ReviewsPage />
              </Suspense>
            ),
          },
          {
            path: "manager-news",
            element: (
              <Suspense>
                <ManagementNews />
              </Suspense>
            ),
          },
          {
            path: "report",
            element: (
              <Suspense>
                <ReportPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: paths.user.path,
        element: (
          <Suspense>
            <Profile />
          </Suspense>
        ),
        id: createRouteId("user"),
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <ProfileSettings />
              </Suspense>
            ),
          },
          {
            path: "history",
            element: (
              <Suspense>
                <HistoryPage />
              </Suspense>
            ),
          },
          {
            path: "reviews",
            element: (
              <Suspense>
                <ReviewsPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter([
  {
    element: (
      <Suspense fallback={<LoaderPage />}>
        <Layout />
      </Suspense>
    ),
    errorElement: <Error />,
    path: "/",
    children: [
      {
        path: "/:lang",
        element: <LanguagePath />,
        children: components,
      },
    ],
  },
]);

export default router;
